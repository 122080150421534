import React from "react";
import Headerdetail from "../../../component/headerdetail/headerdetail.jsx"; // Assuming PascalCase for the component name
import data from "../section/promocard/promocard.json";
import Promo from "../../../component/promo/promo.jsx";
import Promoimages from "../../../media/detail/promo/promo.js";
import "./pasadena.scss";
import pasadena from "../../../media/detail/produkrumah/pasadena/pasadena.js";
import Slider from "react-slick";
import {
  faBed,
  faHouse,
  faMaximize,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Pasadena = () => {
  // Use PascalCase for the component name
  const frescoImageUrl = require("../../../media/home/detail/banner/pasadenagrand.webp"); // Replace with your image path

  return (
    <div>
      <Headerdetail imageUrl={frescoImageUrl} />
      {/* Other content of your Fresco component */}
      <div className="container-promocard">
        {data.Pasadena.map((promo, index) => {
          // Determine the logo dynamically
          const fotopromo = Promoimages[promo.imagehs];
          return (
            <Promo
              key={index}
              imagehs={fotopromo} // Dynamically import logo images
              titleabout={promo.titleabout}
              deskabout={promo.deskabout}
              titlepromo={promo.titlepromo}
              details={promo.details}
              whatsappLink={promo.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="devider">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="divider-img-691399"
          viewBox="0 0 1010 137"
          preserveAspectRatio="none"
        >
          <path
            d="M 0,137 V 59.03716 c 158.97703,52.21241 257.17659,0.48065 375.35967,2.17167 118.18308,1.69101 168.54911,29.1665 243.12679,30.10771 C 693.06415,92.25775 855.93515,29.278599 1080,73.61449 V 137 Z"
            style={{ opacity: "1" }}
          ></path>
          <path
            d="M 0,10.174557 C 83.419822,8.405668 117.65911,41.78116 204.11379,44.65308 290.56846,47.52499 396.02558,-7.4328 620.04248,94.40134 782.19141,29.627636 825.67279,15.823104 1080,98.55518 V 137 H 0 Z"
            style={{ opacity: 0.5 }}
          ></path>
          <path
            d="M 0,45.10182 C 216.27861,-66.146913 327.90348,63.09813 416.42665,63.52904 504.94982,63.95995 530.42054,22.125806 615.37532,25.210412 700.33012,28.295019 790.77619,132.60682 1080,31.125744 V 137 H 0 Z"
            style={{ opacity: 0.25 }}
          ></path>
        </svg>
      </div>
      <div className="judul-pasadena">OUR HOME</div>
      <div className="card-container">
        <div className="card-carousel">
          <Slider {...settings}>
            {pasadena.map((image, index) => (
              <img
                className="img"
                src={image}
                alt={`Slide ${index}`}
                key={index}
              />
            ))}
          </Slider>
        </div>
        <div className="card1">
          <div className="card">
            <div className="cluster-card-title">
              <div className="cluster-name">
                <div className="cluster-name-sub">L8x15+ (178)</div>
                <div className="cluster-name-sub2">Pasadena Grand</div>
              </div>
              <div className="cluster-price">
                <div className="cluster-price-sub">20</div>
                <div className="cluster-price-sub2">Juta/Bulan</div>
              </div>
            </div>
            <hr className="card-separator" />
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span>&nbsp;:178m²&nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faHouse} />
              <span>&nbsp;:142m²&nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faBed} />
              <span>&nbsp;: 5 &nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faShower} />
              <span>&nbsp;: 4&nbsp;&nbsp;</span>
            </div>
            <div className="cluster-button">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(L8)%20https://marketingparamountland.com/pasadena-grand&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="cluster-whatsapp"
              >
                <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                &nbsp;Whatsapp
              </button>
            </div>
          </div>
          <div className="card">
            <div className="cluster-card-title">
              <div className="cluster-name">
                <div className="cluster-name-sub">L9x18+ (228) </div>
                <div className="cluster-name-sub2">Pasadena Grand</div>
              </div>
              <div className="cluster-price">
                <div className="cluster-price-sub">26</div>
                <div className="cluster-price-sub2">Juta/Bulan</div>
              </div>
            </div>
            <hr className="card-separator" />
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span>&nbsp;:232m²&nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faHouse} />
              <span>&nbsp;:196m²&nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faBed} />
              <span>&nbsp;: 5 &nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faShower} />
              <span>&nbsp;: 4&nbsp;&nbsp;</span>
            </div>
            <div className="cluster-button">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(L9)%20https://marketingparamountland.com/pasadena-grand&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="cluster-whatsapp"
              >
                <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                &nbsp;Whatsapp
              </button>
            </div>
          </div>
        </div>
        <div className="card1">
          <div className="card">
            <div className="cluster-card-title">
              <div className="cluster-name">
                <div className="cluster-name-sub">L10x18(IRR)</div>
                <div className="cluster-name-sub2">Pasadena Grand</div>
              </div>
              <div className="cluster-price">
                <div className="cluster-price-sub">30</div>
                <div className="cluster-price-sub2">Juta/Bulan</div>
              </div>
            </div>
            <hr className="card-separator" />
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span>&nbsp;:274m²&nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faHouse} />
              <span>&nbsp;:218m²&nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faBed} />
              <span>&nbsp;: 5 &nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faShower} />
              <span>&nbsp;: 4&nbsp;&nbsp;</span>
            </div>
            <div className="cluster-button">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(L10)%20https://marketingparamountland.com/pasadena-grand&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="cluster-whatsapp"
              >
                <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                &nbsp;Whatsapp
              </button>
            </div>
          </div>
          <div className="card">
            <div className="cluster-card-title">
              <div className="cluster-name">
                <div className="cluster-name-sub">L12x18</div>
                <div className="cluster-name-sub2">Pasadena Grand</div>
              </div>
              <div className="cluster-price">
                <div className="cluster-price-sub">32</div>
                <div className="cluster-price-sub2">Juta/Bulan</div>
              </div>
            </div>
            <hr className="card-separator" />
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span>&nbsp;:216m²&nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faHouse} />
              <span>&nbsp;:283m²&nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faBed} />
              <span>&nbsp;: 5 &nbsp;&nbsp;</span>
              <FontAwesomeIcon icon={faShower} />
              <span>&nbsp;: 4&nbsp;&nbsp;</span>
            </div>
            <div className="cluster-button">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(L12)%20https://marketingparamountland.com/pasadena-grand&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="cluster-whatsapp"
              >
                <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                &nbsp;Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pasadena;
