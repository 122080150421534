import Almahs from "./alma.webp";
import Frescohs from "./fresco.webp";
import Granadahs from "../../../media/detail/promo/granada.webp";
import Materahs from "../../../media/detail/promo/matera.webp";
import Mentenggrandhs from "../../../media/detail/promo/mentenggrand.webp";
import Narahs from "../../../media/detail/promo/nara.webp";
import Newmentenghs from "../../../media/detail/promo/newmenteng.webp";
import Pasadenahs from "../../../media/detail/promo/pasadena.webp";
import Zumahs from "../../../media/detail/promo/zuma.webp";
import Homehs from "../../../media/detail/promo/promo.webp";
import Pasadenavhs from "../../../media/detail/promo/pasadenav.png";

const Promoimages = {
  Almahs,
  Frescohs,
  Granadahs,
  Materahs,
  Mentenggrandhs,
  Narahs,
  Newmentenghs,
  Pasadenahs,
  Zumahs,
  Homehs,
  Pasadenavhs,
};

export default Promoimages;
