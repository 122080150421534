import React from "react";
import Headerdetail from "../../../component/headerdetail/headerdetail.jsx"; // Assuming PascalCase for the component name
import data from "../section/promocard/promocard.json";
import Promo from "../../../component/promo/promo.jsx";
import Promoimages from "../../../media/detail/promo/promo.js";
import matera12 from "../../../media/detail/produkrumah/matera/matera12/matera12.js";
import matera12x25 from "../../../media/detail/produkrumah/matera/matera12x25/matera12x25.js";
import materal10 from "../../../media/detail/produkrumah/matera/materal10/materal10.js";
import materal9 from "../../../media/detail/produkrumah/matera/materal9/materal9.js";
import Cardrumahdetail from "../../../component/cardrumahdetail/cardrumahdetail.jsx";
import matera from "./matera.json";
import "./matera.scss";

const imageMap = {
  materal9,
  materal10,
  matera12,
  matera12x25,
};
const Matera = () => {
  // Use PascalCase for the component name
  const frescoImageUrl = require("../../../media/home/detail/banner/matera.webp"); // Replace with your image path

  return (
    <div>
      <Headerdetail imageUrl={frescoImageUrl} />
      {/* Other content of your Fresco component */}
      <div className="container-promocard">
        {data.Matera.map((promo, index) => {
          // Determine the logo dynamically
          const fotopromo = Promoimages[promo.imagehs];
          return (
            <Promo
              key={index}
              imagehs={fotopromo} // Dynamically import logo images
              titleabout={promo.titleabout}
              deskabout={promo.deskabout}
              titlepromo={promo.titlepromo}
              details={promo.details}
              whatsappLink={promo.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="devider">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="divider-img-691399"
          viewBox="0 0 1010 137"
          preserveAspectRatio="none"
        >
          <path
            d="M 0,137 V 59.03716 c 158.97703,52.21241 257.17659,0.48065 375.35967,2.17167 118.18308,1.69101 168.54911,29.1665 243.12679,30.10771 C 693.06415,92.25775 855.93515,29.278599 1080,73.61449 V 137 Z"
            style={{ opacity: "1" }}
          ></path>
          <path
            d="M 0,10.174557 C 83.419822,8.405668 117.65911,41.78116 204.11379,44.65308 290.56846,47.52499 396.02558,-7.4328 620.04248,94.40134 782.19141,29.627636 825.67279,15.823104 1080,98.55518 V 137 H 0 Z"
            style={{ opacity: 0.5 }}
          ></path>
          <path
            d="M 0,45.10182 C 216.27861,-66.146913 327.90348,63.09813 416.42665,63.52904 504.94982,63.95995 530.42054,22.125806 615.37532,25.210412 700.33012,28.295019 790.77619,132.60682 1080,31.125744 V 137 H 0 Z"
            style={{ opacity: 0.25 }}
          ></path>
        </svg>
      </div>
      <div className="judul-matera">OUR HOME</div>
      <div className="cardhome-containerm">
        {matera.cards.map((card, index) => {
          // Determine the logo dynamically
          return (
            <Cardrumahdetail
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default Matera;
