import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/home/home.jsx";
import Navbar from "./component/navbar/navbar.jsx";
import Fresco from "./pages/detailproduk/fresco/fresco.jsx";
import Granada from "./pages/detailproduk/granada/granada.jsx";
import Zuma from "./pages/detailproduk/zuma/zuma.jsx";
import Nara from "./pages/detailproduk/nara/nara.jsx";
import Alma from "./pages/detailproduk/alma/alma.jsx";
import Pasadena from "./pages/detailproduk/pasadena/pasadena.jsx";
import Matera from "./pages/detailproduk/matera/matera.jsx";
import Newmenteng from "./pages/detailproduk/newmenteng/newmenteng.jsx";
import Mentenggrand from "./pages/detailproduk/mentenggrand/mentenggrand.jsx";
import ScrollToTop from "./component/ScrollToTop/ScrollToTop.jsx";
import Footer from "./component/footer/footer.jsx";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Pasadenav from "./pages/detailproduk/pasadenav/pasadenav.jsx";

const fungsiganteng = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20()%20https://marketingparamountland.com/&type=phone_number&app_absent=0";
  window.location.href = whatsappLink;
};

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/fresco" element={<Fresco />} />
          <Route path="/granada" element={<Granada />} />
          <Route path="/zuma" element={<Zuma />} />
          <Route path="/nara" element={<Nara />} />
          <Route path="/pasadena" element={<Pasadena />} />
          <Route path="/mentenggrand" element={<Mentenggrand />} />
          <Route path="/newmenteng" element={<Newmenteng />} />
          <Route path="/matera" element={<Matera />} />
          <Route path="/alma" element={<Alma />} />
          <Route path="/pasadenavillage" element={<Pasadenav />} />
        </Routes>
        <a
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20()%20https://marketingparamountland.com/&type=phone_number&app_absent=0"
        >
          <FloatingWhatsApp />
        </a>

        <Footer />
        <div className="floatingwamobile">
          <button onClick={fungsiganteng} className="buttonmobile">
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;Whatsapp Sekarang
          </button>
        </div>
      </Router>
    </div>
  );
}

export default App;
