import Pv81 from "./pv8 (1).webp";
import Pv82 from "./pv8 (2).webp";
import Pv83 from "./pv8 (3).webp";
import Pv84 from "./pv8 (4).webp";
import Pv85 from "./pv8 (5).webp";
import Pv86 from "./pv8 (6).webp";
import Pv87 from "./pv8 (7).webp";
import Pv88 from "./pv8 (8).webp";
import Pv89 from "./pv8 (9).webp";
import Pv810 from "./pv8 (10).webp";
import Pv811 from "./pv8 (11).webp";
import Pv812 from "./pv8 (12).webp";
import Pv813 from "./pv8 (13).webp";
import Pv814 from "./pv8 (14).webp";
import Pv815 from "./pv8 (15).webp";
import Pv816 from "./pv8 (16).webp";
import Pv817 from "./pv8 (17).webp";

const pv8 = [
  Pv81,
  Pv82,
  Pv83,
  Pv84,
  Pv85,
  Pv86,
  Pv87,
  Pv88,
  Pv89,
  Pv810,
  Pv811,
  Pv812,
  Pv813,
  Pv814,
  Pv815,
  Pv816,
  Pv817,
];

export default pv8;
