import {
  faBars,
  faHouse,
  faLocationDot,
  faMaximize,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./cardrumahhome.scss";

const cardrumahcomercial = ({
  imagerumah, // Dynamically import logo images
  titlerumah,
  subtitlerumah,
  price,
  subprice,
  details,
  whatsappLink,
}) => {
  return (
    <div className="cardrumahhome">
      <div className="card">
        <div className="gambar">
          <img className="imgcarousel" src={imagerumah} alt="foto" />
        </div>
        <div className="card-content">
          <div className="container-title-card">
            <div className="titlecard">
              <div className="judul-rumah1">{titlerumah}</div>
              <div className="sub-judul-rumah">
                <FontAwesomeIcon color="red" icon={faLocationDot} />{" "}
                {subtitlerumah}
              </div>
              <div className="gridspek">
                <FontAwesomeIcon icon={faMaximize} />
                <span> &nbsp; : {details.areas} &nbsp;</span>
                <FontAwesomeIcon icon={faHouse} />
                <span> {details.buildingSize}</span>
                <FontAwesomeIcon icon={faToilet} />
                <span> {details.toilet}</span>
                <FontAwesomeIcon icon={faBars} />
                <span> {details.lantai}</span>
              </div>
              <div className="cicilan">
                <div className="start">Start From</div>
                <div className="det">
                  <div className="angka">{price}</div>
                  <div className="ket">{subprice}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-button">
            <div className="containerwhatsapp">
              <button
                onClick={() => window.open(whatsappLink, "_blank")}
                className="whatsapp"
              >
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default cardrumahcomercial;
