import Alma101 from "./alma10 (1).webp";
import Alma102 from "./alma10 (2).webp";
import Alma103 from "./alma10 (3).webp";
import Alma104 from "./alma10 (4).webp";
import Alma105 from "./alma10 (5).webp";
import Alma106 from "./alma10 (6).webp";
import Alma107 from "./alma10 (7).webp";
import Alma108 from "./alma10 (8).webp";
import Alma109 from "./alma10 (9).webp";
import Alma1010 from "./alma10 (10).webp";
import Alma1011 from "./alma10 (11).webp";

const Alma10 = [
  Alma101,
  Alma102,
  Alma103,
  Alma104,
  Alma105,
  Alma106,
  Alma107,
  Alma108,
  Alma109,
  Alma1010,
  Alma1011,
];

export default Alma10;
