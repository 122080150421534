import Alma121 from "./alma12 (1).webp";
import Alma122 from "./alma12 (2).webp";
import Alma123 from "./alma12 (3).webp";
import Alma124 from "./alma12 (4).webp";
import Alma125 from "./alma12 (5).webp";
import Alma126 from "./alma12 (6).webp";
import Alma127 from "./alma12 (7).webp";
import Alma128 from "./alma12 (8).webp";
import Alma129 from "./alma12 (9).webp";
import Alma1210 from "./alma12 (10).webp";

const Alma12 = [
  Alma121,
  Alma122,
  Alma123,
  Alma124,
  Alma125,
  Alma126,
  Alma127,
  Alma128,
  Alma129,
  Alma1210,
];

export default Alma12;
