import materal9_1 from "./1-10.webp";
import materal9_2 from "./2-13.webp";
import materal9_3 from "./3-13.webp";
import materal9_4 from "./4-13.webp";
import materal9_5 from "./5-14.webp";
import materal9_6 from "./6-12.webp";
import materal9_7 from "./7-8.webp";
import materal9_8 from "./8-10.webp";
import materal9_9 from "./9-9.webp";
import materal9_10 from "./10-8.webp";
import materal9_11 from "./11-3.webp";
import materal9_12 from "./12-4.webp";
import materal9_13 from "./13-2.webp";
import materal9_14 from "./14-2.webp";

const materal9 = [
  materal9_1,
  materal9_2,
  materal9_3,
  materal9_4,
  materal9_5,
  materal9_6,
  materal9_7,
  materal9_8,
  materal9_9,
  materal9_10,
  materal9_11,
  materal9_12,
  materal9_13,
  materal9_14,
];

export default materal9;
