import matera12x25_1 from "./52.webp";
import matera12x25_2 from "./53.webp";
import matera12x25_3 from "./54.webp";
import matera12x25_4 from "./55.webp";
import matera12x25_5 from "./56.webp";
import matera12x25_6 from "./57.webp";
import matera12x25_7 from "./58.webp";
import matera12x25_8 from "./59.webp";
import matera12x25_9 from "./60.webp";
import matera12x25_10 from "./61.webp";
import matera12x25_12 from "./62.webp";
import matera12x25_13 from "./63.webp";
import matera12x25_14 from "./64.webp";
import matera12x25_15 from "./65.webp";

const matera12x25 = [
  matera12x25_1,
  matera12x25_2,
  matera12x25_3,
  matera12x25_4,
  matera12x25_5,
  matera12x25_6,
  matera12x25_7,
  matera12x25_8,
  matera12x25_9,
  matera12x25_10,
  matera12x25_12,
  matera12x25_13,
  matera12x25_14,
  matera12x25_15,
];

export default matera12x25;
