import zuma71 from "./zuma-8.webp";
import zuma72 from "./2-1.webp";
import zuma73 from "./3-1.webp";
import zuma74 from "./4-1.webp";
import zuma75 from "./5-1.webp";
import zuma76 from "./6-1.webp";
import zuma77 from "./7-1.webp";
import zuma78 from "./8-1.webp";
import zuma79 from "./9-1.webp";

const zuma7 = [
  zuma71,
  zuma72,
  zuma73,
  zuma74,
  zuma75,
  zuma76,
  zuma77,
  zuma78,
  zuma79,
];

export default zuma7;
