import Almahome from "./alma.webp";
import Frescohome from "./fresco.webp";
import Granadahome from "./granada.webp";
import Materahome from "./matera.webp";
import Mentenggrandhome from "./menteng.webp";
import Narahome from "./nara.webp";
import Newmentenghome from "./newmenteng.webp";
import Pasadenahome from "./pasadena.webp";
import Pasadenavhome from "./pasadenav.webp";
import Zumahome from "./zuma.webp";

const Rumahimages = {
  Almahome,
  Frescohome,
  Granadahome,
  Materahome,
  Mentenggrandhome,
  Narahome,
  Newmentenghome,
  Pasadenahome,
  Pasadenavhome,
  Zumahome,
};

export default Rumahimages;
