import newmenteng1 from "./1-5.webp";
import newmenteng2 from "./2-7.webp";
import newmenteng3 from "./3-7.webp";
import newmenteng4 from "./4-6.webp";
import newmenteng5 from "./5-7.webp";
import newmenteng6 from "./6-6.webp";
import newmenteng7 from "./7-5.webp";
import newmenteng8 from "./8-7.webp";
import newmenteng9 from "./9-5.webp";
import newmenteng10 from "./10-5.webp";
import newmenteng11 from "./11-1.webp";
import newmenteng12 from "./12-1.webp";
import newmenteng13 from "./13.webp";
import newmenteng14 from "./14.webp";
import newmenteng15 from "./15.webp";

const newmentengfoto = [
  newmenteng1,
  newmenteng2,
  newmenteng3,
  newmenteng4,
  newmenteng5,
  newmenteng6,
  newmenteng7,
  newmenteng8,
  newmenteng9,
  newmenteng10,
  newmenteng11,
  newmenteng12,
  newmenteng13,
  newmenteng14,
  newmenteng15,
];

export default newmentengfoto;
