import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./promo.scss";

const Promo = ({
  imagehs,
  titleabout,
  deskabout,
  titlepromo,
  details,
  whatsappLink,
}) => {
  const renderDetail = (detail) => {
    const parts = detail.split(" ");
    const iconClass = parts.shift(); // Remove and get the first part as icon class

    // Determine FontAwesome icon based on class name
    let iconComponent = null;
    switch (iconClass) {
      case "fa-check":
        iconComponent = <FontAwesomeIcon icon={faCheck} />;
        break;
      default:
        break;
    }

    // Render the detail item
    return (
      <div className="penawaranpoin">
        {iconComponent && <span>{iconComponent}</span>}
        <span style={{ color: "white" }}>&nbsp;&nbsp;{parts.join(" ")}</span>
      </div>
    );
  };

  return (
    <div className="container-promo">
      <div className="container-about">
        <div className="judul-about">{titleabout}</div>
        <div className="desk-about">{deskabout}</div>
      </div>
      <div className="promo">
        <div className="containercontent">
          <div id="promo" className="judul-promo">
            {titlepromo}
          </div>
          <div className="pointpenawaran">
            {Object.values(details).map((detail, index) =>
              renderDetail(detail)
            )}
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <div className="button-wa-promo">
            <button
              onClick={() => window.open(whatsappLink, "_blank")}
              className="whatsapp"
            >
              <FontAwesomeIcon icon={faWhatsapp} /> Get More Promo
            </button>
          </div>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={imagehs} alt="hardsell" />
        </div>
      </div>
    </div>
  );
};

export default Promo;
