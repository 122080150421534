import materal10_1 from "./15-2.webp";
import materal10_2 from "./16-1.webp";
import materal10_3 from "./17-1.webp";
import materal10_4 from "./18-1.webp";
import materal10_5 from "./19-1.webp";
import materal10_6 from "./20-1.webp";
import materal10_7 from "./21-1.webp";
import materal10_8 from "./22-1.webp";
import materal10_9 from "./23-1.webp";
import materal10_10 from "./24-1.webp";
import materal10_12 from "./25-2.webp";
import materal10_13 from "./26-4.webp";
import materal10_14 from "./27-2.webp";
import materal10_15 from "./28-1.webp";
import materal10_16 from "./29-1.webp";
import materal10_17 from "./30-1.webp";
import materal10_18 from "./31.webp";
import materal10_19 from "./32.webp";

const materal10 = [
  materal10_1,
  materal10_2,
  materal10_3,
  materal10_4,
  materal10_5,
  materal10_6,
  materal10_7,
  materal10_8,
  materal10_9,
  materal10_10,
  materal10_12,
  materal10_13,
  materal10_14,
  materal10_15,
  materal10_16,
  materal10_17,
  materal10_18,
  materal10_19,
];

export default materal10;
