import React from "react";
import "./footer.scss";
import Slider from "react-slick";
import footerpasadena from "../../media/detail/produkrumah/pasadena/footerpasadena/footerpasadena";
import logo from "../../media/home/logo-paramount.png";
import map from "../../media/home/map.png";
const footer = () => {
  const settings2 = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const footerwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Lokasi%20https://marketingparamountland.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="footer">
      <div className="footeratas">
        <div className="footerpasadena">
          <div className="content">
            <div className="title">Luxury Amenities Comunity Club</div>
            <div className="subtitle">
              Memberikan tampilan mewah sekaligus elegan, megah dan modern.
            </div>
            <div className="divbutton">
              <button onClick={footerwa} className="button">
                More Info
              </button>
            </div>
          </div>

          <div className="pasadenafootercarousel">
            <Slider {...settings2}>
              {footerpasadena.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt="pasadena"
                  className="pasadenaimage"
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className="footerbawah">
        <div className="footerpertama">
          <div className="footerkiri">
            <img src={logo} className="logo" />
            <div className="logotitle">Marketing Gallery</div>
            <div className="logokontak">Contact : +6282122005507 </div>
          </div>
          <div className="footertengah">
            <div className="linktitle">Quick Links</div>
            <a href="#about">About Us</a>
            <a href="#promo">Promo</a>
            <a href="#rumah">Rumah Paramount</a>
            <a href="#ruko">Ruko Paramount</a>
          </div>
          <div className="footerkanan">
            <img src={map} className="img" />
          </div>
        </div>
        <div className="footerkedua">© Paramount Land</div>
      </div>
    </div>
  );
};

export default footer;
