import Pv91 from "./pv9 (1).webp";
import Pv92 from "./pv9 (2).webp";
import Pv93 from "./pv9 (3).webp";
import Pv94 from "./pv9 (4).webp";
import Pv95 from "./pv9 (5).webp";
import Pv96 from "./pv9 (6).webp";
import Pv97 from "./pv9 (7).webp";
import Pv98 from "./pv9 (8).webp";
import Pv99 from "./pv9 (9).webp";
import Pv910 from "./pv9 (10).webp";
import Pv911 from "./pv9 (11).webp";
import Pv912 from "./pv9 (12).webp";
import Pv913 from "./pv9 (13).webp";
import Pv914 from "./pv9 (14).webp";
import Pv915 from "./pv9 (15).webp";
import Pv916 from "./pv9 (16).webp";
import Pv917 from "./pv9 (17).webp";
import Pv918 from "./pv9 (18).webp";
import Pv919 from "./pv9 (19).webp";
import Pv920 from "./pv9 (20).webp";
import Pv921 from "./pv9 (21).webp";
import Pv922 from "./pv9 (22).webp";
import Pv923 from "./pv9 (23).webp";

const pv9 = [
  Pv91,
  Pv92,
  Pv93,
  Pv94,
  Pv95,
  Pv96,
  Pv97,
  Pv98,
  Pv99,
  Pv910,
  Pv911,
  Pv912,
  Pv913,
  Pv914,
  Pv915,
  Pv916,
  Pv917,
  Pv918,
  Pv919,
  Pv920,
  Pv921,
  Pv922,
  Pv923,
];

export default pv9;
