import matera12_1 from "./34.webp";
import matera12_2 from "./35.webp";
import matera12_3 from "./36.webp";
import matera12_4 from "./37.webp";
import matera12_5 from "./38.webp";
import matera12_6 from "./39.webp";
import matera12_7 from "./40.webp";
import matera12_8 from "./41.webp";
import matera12_9 from "./42.webp";
import matera12_10 from "./43.webp";
import matera12_11 from "./44.webp";
import matera12_12 from "./45.webp";
import matera12_13 from "./46.webp";
import matera12_14 from "./47.webp";
import matera12_15 from "./48.webp";
import matera12_16 from "./49.webp";
import matera12_17 from "./50.webp";
import matera12_18 from "./51.webp";

const matera12 = [
  matera12_1,
  matera12_2,
  matera12_4,
  matera12_5,
  matera12_6,
  matera12_7,
  matera12_8,
  matera12_9,
  matera12_10,
  matera12_11,
  matera12_12,
  matera12_13,
  matera12_14,
  matera12_15,
  matera12_16,
  matera12_17,
  matera12_18,
];

export default matera12;
