import zuma61 from "./1.webp";
import zuma62 from "./2.webp";
import zuma63 from "./3.webp";
import zuma64 from "./4.webp";
import zuma65 from "./5.webp";
import zuma66 from "./6.webp";
import zuma67 from "./7.webp";
import zuma68 from "./8.webp";
import zuma69 from "./9.webp";
import zuma610 from "./10.webp";

const zuma6 = [
  zuma61,
  zuma62,
  zuma63,
  zuma64,
  zuma65,
  zuma66,
  zuma67,
  zuma68,
  zuma69,
  zuma610,
];

export default zuma6;
