import React from "react";
import data from "../../../detailproduk/section/promocard/promocard.json";
import Promo from "../../../../component/promo/promo.jsx";
import Promoimages from "../../../../media/detail/promo/promo.js";
import "./promohome.scss";

const promohome = () => {
  return (
    <div id="promo" className="promohome">
      <div className="container-promo">
        <div className="judul-promo">
          <div>
            <h2>#Promo</h2>
          </div>
          <h1>OUR PROMO</h1>
        </div>
      </div>
      <div className="container-promocard">
        {data.home.map((promo, index) => {
          // Determine the logo dynamically
          const fotopromo = Promoimages[promo.imagehs];
          return (
            <Promo
              key={index}
              imagehs={fotopromo} // Dynamically import logo images
              titleabout={promo.titleabout}
              deskabout={promo.deskabout}
              titlepromo={promo.titlepromo}
              details={promo.details}
              whatsappLink={promo.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default promohome;
