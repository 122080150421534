import React from "react";
import "./navbar.scss";
import imglogo from "../../media/home/logo/logo-landscape.webp";
import { Link } from "react-router-dom";

const navbar = () => {
  return (
    <div className="container-navbar">
      <div className="navbar">
        <div className="logo-navbar">
          <Link to="/">
            <img className="img-logo" src={imglogo} alt="logo-bukitpodomoro" />
          </Link>
        </div>
        <div className="menu-navbar">
          <ul>
            <li>
              <a href="/#about">About Us</a>
            </li>
            <li>
              <a href="/#promo">Promo</a>
            </li>
            <li>
              <a href="/#rumah">Residentials</a>
            </li>
            <li>
              <a href="/#ruko">Commercials</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default navbar;
