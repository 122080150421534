import hudson from "./hudson.webp";
import santamonika from "./santamonika.webp";
import santaanna from "./santaanna.webp";
import illago from "./illago.webp";
import pasar from "./pasarmodern.webp";
import hampton from "./Hampton.webp";
import hamptoneast from "./hamptoneast.png";
import pasadenasquare from "./pasadenasquare.png";

const ruko = {
  hudson,
  santamonika,
  santaanna,
  illago,
  pasar,
  hampton,
  hamptoneast,
  pasadenasquare,
};

export default ruko;
