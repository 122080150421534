import React from "react";
import "./home.scss";
import Header from "./section/header/header";
import About from "./section/about/about";
import Promohome from "./section/promohome/promohome";
import Produk from "./section/produk/produk";
import ScrollToHashElement from "./ScrollToHashElement";

const home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Header />
      <About />
      <Promohome />
      <Produk />
    </div>
  );
};

export default home;
