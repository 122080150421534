import Fresco51 from "./fresco5 (1).webp";
import Fresco52 from "./fresco5 (2).webp";
import Fresco53 from "./fresco5 (3).webp";
import Fresco54 from "./fresco5 (4).webp";
import Fresco55 from "./fresco5 (5).webp";
import Fresco56 from "./fresco5 (6).webp";

const Fresco5 = [Fresco51, Fresco52, Fresco53, Fresco54, Fresco55, Fresco56];

export default Fresco5;
