import React from "react";
import Cardrumahhome from "../../../../component/cardrumahhome/cardrumahhome.jsx";
import Rumahimages from "../../../../media/home/produk/rumah/rumah.js";
import produkcard from "./produkcard.json";
import "./produk.scss";
import ruko from "../../../../media/home/produk/ruko/ruko.js";
import commercialcard from "./commercialcard.json";
import Cardrumahcomercial from "../../../../component/cardrumahhome/cardrumahcomercial.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Newlaunchruko from "../newlaunchruko/newlaunchruko.jsx";
import Newlaunchrumah from "../newlaunchrumah/newlaunchrumah.jsx";

const Produk = () => {
  return (
    <div className="produk">
      <div className="ket-cardrumah">
        <div className="judul">PRODUCT'S</div>
        <div id="rumah" className="subjudul">
          Residential's
        </div>
      </div>
      <div className="section-newlaunch">
        <Newlaunchrumah />
      </div>

      <div className="cardhome1-container">
        {produkcard.Cardrumah.map((cards, index) => {
          // Determine the logo dynamically
          const fotorumah = Rumahimages[cards.imagerumah];
          return (
            <Cardrumahhome
              key={index}
              imagerumah={fotorumah} // Dynamically import logo images
              titlerumah={cards.titlerumah}
              subtitlerumah={cards.subtitlerumah}
              price={cards.price}
              subprice={cards.subprice}
              details={cards.details}
              whatsappLink={cards.whatsappLink}
              pagelink={cards.pagelink} // Pass the WhatsApp link// Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="ket-cardrumah">
        <div id="ruko" className="subjudul">
          Commecial's
        </div>
      </div>
      <div className="section-newlaunch">
        <Newlaunchruko />
      </div>

      <div className="cardhome1-container">
        {commercialcard.Cardrumah.map((cards, index) => {
          // Determine the logo dynamically
          const fotorumah = ruko[cards.imagerumah];
          return (
            <Cardrumahcomercial
              key={index}
              imagerumah={fotorumah} // Dynamically import logo images
              titlerumah={cards.titlerumah}
              subtitlerumah={cards.subtitlerumah}
              price={cards.price}
              subprice={cards.subprice}
              details={cards.details}
              whatsappLink={cards.whatsappLink}
              pagelink={cards.pagelink} // Pass the WhatsApp link// Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default Produk;
