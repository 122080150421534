import React from "react";
import "./about.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Iklanimages from "../../../../media/home/iklan";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const about = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const o8 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(NUP)%20https://marketingparamountland.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-about">
      <div id="about" className="about">
        <div className="carousel-launching-banner">
          <div className="launch-banner">
            <Slider {...settings}>
              {Iklanimages.map((image, index) => (
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt={`Astha ${index + 1}`}
                />
              ))}
            </Slider>
          </div>
          <div className="button-cta">
            <button onClick={o8} className="about-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Info NUP
            </button>
          </div>
        </div>
        <div className="content-about">
          <div className="judul-about">
            Building Homes and People with Heart
          </div>
          <div className="desk-about">
            PT Paramount Land membangun kawasan perumahan dan komersial yang
            dikenal sebagai Paramount Land Gading Serpong di sekitar Jakarta,
            Indonesia. Dengan luas sekitar 1.500 hektar, kawasan Paramount Land
            di Tangerang, Banten, terkenal dengan pengembangan perumahan,
            manajemen proyek dan konstruksi, manajemen kawasan, dan manajemen
            properti. Area ini memiliki semua fasilitas yang diperlukan,
            termasuk rumah sakit, area komersial, hotel, restoran, sekolah,
            universitas, transportasi umum, keamanan, olahraga, dan rekreasi,
            serta taman umum.
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
