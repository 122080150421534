import pasadena1 from "./2-12.webp";
import pasadena2 from "./12-2.webp";

import pasadena4 from "./13-1.webp";
import pasadena5 from "./15-1.webp";
import pasadena6 from "./16.webp";
import pasadena7 from "./17.webp";
import pasadena8 from "./18.webp";
import pasadena9 from "./19.webp";
import pasadena10 from "./20.webp";
import pasadena11 from "./21.webp";
import pasadena12 from "./22.webp";
import pasadena13 from "./23.webp";
import pasadena14 from "./24.webp";
import pasadena15 from "./25.webp";
import pasadena16 from "./26.webp";
import pasadena17 from "./27.webp";

const pasadena = [
  pasadena2,
  pasadena1,
  pasadena4,
  pasadena5,
  pasadena6,
  pasadena7,
  pasadena8,
  pasadena9,
  pasadena10,
  pasadena11,
  pasadena12,
  pasadena13,
  pasadena14,
  pasadena15,
  pasadena16,
  pasadena17,
];

export default pasadena;
