import React from "react";
import "./headerdetail.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const headerdetail = ({ imageUrl }) => {
  const styles = {
    backgroundImage: `url(${imageUrl})`,
  };
  const wacontact = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20()%20https://marketingparamountland.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="header">
      <div className="container-image" style={styles}></div>
      <div className="contact-header">
        <div className="judul-contact">Marketing Paramount Land</div>
        <div className="nomor-contact">Contact : +6282122005507</div>
        <div className="button-contact">
          <button className="contactus" onClick={wacontact}>
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default headerdetail;
